header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    margin-bottom: 23rem;
}

.container .header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header__container h1 {
    text-align: center;
    display:inline-block;
    overflow:hidden;
    white-space: nowrap;
    margin-left: 355px;
    animation: showup 7s infinite;
}

.header__container h5 {
    text-align: center;
}

/* .animated {
    overflow:hidden;
    white-space:nowrap;
    margin-left: 335px;
    width: 0px;
    animation: reveal 7s infinite;
} */

/* .animated h5 {
    font-size: 14px;
    animation: slidein 7s infinite;
} */

/* @keyframes showup {
    0% {opacity:0;}
    20% {opacity:1;}
    80% {opacity:1;}
    100% {opacity:0;}
}

@keyframes slidein {
    0% { margin-left:-800px; }
    20% { margin-left:-800px; }
    35% { margin-left:0px; }
    100% { margin-left:0px; }
}

@keyframes reveal {
    0% {opacity:0;width:0px;}
    20% {opacity:1;width:0px;}
    30% {width:355px;}
    80% {opacity:1;}
    100% {opacity:0;width:355px;}
} */

/* ------------------- CTA ---------------------- */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ------------------- header socials ---------------------- */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    margin-left: 9rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ------------------- image ---------------------- */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 3rem 1rem 1rem 1.5rem;
}

/* ------------------- scroll down ---------------------- */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    margin-right: 9rem;
    margin-bottom: 1.5rem;
}

/* ---------------------MEDIA QUERIES (medium)--------------------- */

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
        margin-bottom: 10rem;
    }

    .scroll__down {
        margin-bottom: 38rem;
    }

    .header__socials {
        margin-bottom: 36.4rem;
    }

    .header__container h1 {
        margin-left: 170px;
    }

    /* .animated {
        margin-left: 5px;
    } */
}
/* ---------------------MEDIA QUERIES (small)--------------------- */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
        margin-bottom: 7rem;
    }

    .header__socials, .scroll__down {
        display: none;
    }

    .header__container h1 {
        margin-left: 18px;
    }

    .animated {
        margin-left: 0;
    }
}